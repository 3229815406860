import React, { Component } from "react"
import { graphql } from "gatsby"
import Layout from "../components/Layout/layout"
import RichText from "../components/Modules/RichText/richText"
import Wrapper from "../components/Layout/Wrapper/wrapper"
import { Title } from "../components/Layout/Title/title"
//import SEO from "../components/Modules/SEO/seo";
//import { checkUtmParams } from "../services/sem/checkUtmParams";
import {Helmet} from "react-helmet";

class LegalTemplate extends Component {
/*    componentDidMount() {
        checkUtmParams();
    }*/

    render() {
        const page = this.props.data.contentfulInstructionsPost
        const site = this.props.data.site
        return (
            <Layout hideMenuButton={false}>
                <Helmet>
                    <title>{page.title}</title>
                    <meta name="description" content={page.metaDescription.metaDescription} />
                </Helmet>
                <div>
                    <Wrapper classNames={`m-wrapper--instructionsPage`}>
                        <Title grand content={page.title} />
                        <RichText customClass={`m-richText--${page.contentCustomClass}`} content={page.content.json}/>
                    </Wrapper>
                </div>
            </Layout>
        )
    }
}

export default LegalTemplate

export const pageQuery = graphql`
    query($slug: String) {
        contentfulInstructionsPost(slug: { eq: $slug }) {
            title
            metaDescription{
                metaDescription
            }
            content {
                json
            }
            contentCustomClass
        }
    }
`
